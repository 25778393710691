<template>
	<div style="    margin: 30px 20px;">
		<div style="width: 100%;min-height:1000px;background: #ffffff">
			<div class="content" v-html="hello">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		user
	} from '../api/authority'
	import 'highlight.js/styles/monokai-sublime.css';
	export default {
		name: "user",

		data() {
			return {
				hello: ''
			}

		},
		mounted() {
			this.getlmg();

		},
		methods: {

			getlmg() {
				user().then((response) => {
					console.log(response)
					this.hello = response.data
				})
			}

		}

	}
</script>
<style>
	* {
		-webkit-text-size-adjust: none;
	}

	html,
	body {
		/* -webkit-text-size-adjust: 100% !important; */
		-webkit-text-size-adjust: none;
		margin: 0;
		padding: 0;
	}

	.conter {
		margin: 40px 30px;
	}

	/* .title  {
          widows: 171px;
          height: 28px;
          font-size:20px ;
          color: #0A1B33;
          font-weight: 800;
              display: flex;
    justify-content: space-between;
      }
      .title_two{
               font-size:12px ;
          color:#999999 ;

      }
      p{
        max-height: 9999px;
          text-align: left;
          color: #0A1B33 ;
          font-size: 15px;
            padding-bottom: 15px;
            
      }
      font{
          font-size: 15px;
      }
      h1{
          font-size:14px ;
          color: #0A1B33;
          font-weight: 600;
         padding-bottom: 10px;
      }
      h2{
          font-size:13px ;
          color: #0A1B33;
          font-weight: 600;
         padding-bottom: 10px;
      }
        h3{
          font-size:12px ;
          color: #0A1B33;
          font-weight: 550;
          padding-bottom: 10px;
      } */
</style>
